import { useState } from 'react'
import './styles.css'
import Home from "./components/Home"


function App() {

  return (
    <>
      <div className="test">
        <Home/>
      </div>
    </>
  )
}

export default App

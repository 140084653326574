import React from 'react'
import "./Footer.css"

const Footer = () => {
  return (
  <>
    
      <div className="footer-container">
          <a href="https://donor.southtexasblood.org/donor/auth/signin" target="_blank"> 
          <img src="/pics/footer-stbt.png" alt="Image 1" />
          </a>
          <img className='divider' src="/pics/divider.png" alt="Image 2" />
          <a href="/">
          <img src="/pics/logo.png" alt="Image 3" />
          </a>
      </div>
      <div className="copyright"> © South Texas Blood & Tissue.  All rights reserved.</div>
  </>
  )
}

export default Footer
import React, { useState } from 'react'
import "./Navbar.css"





const Navbar = () => {

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
        <div className="navbar-container">
            <a href="/">
              <img 
              src="/pics/logo.png"
              alt="Logo"
              className="logo"
              />
            </a>
              {/* <div className="menu-container"> */}
              <div className={`menu-container ${menuVisible ? 'show' : ''}`}>
                <a href="#overview" className="link-text">
                  <div className='menu-item'>Overview</div>
                </a>
                <a href="#program" className="link-text">
                  <div className='menu-item'>The Program</div>
                </a>
                <a href="#contact" className="link-text">
                  <div className='menu-item'>Contact</div>
                </a>
              </div>
              <div className="menu-icon" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
        </div>
    </>
  )
}

export default Navbar
import React, { useState} from 'react'
import './Three.css'
import ThreeWays from "../images/three.png";
import Navl from "../images/navl.png"
import Navr from "../images/navr.png"

const Three = () => {

  const timages = [
    "./pics/image1.png",
    "./pics/image2.png",
    "./pics/image3.png",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % timages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
    prevIndex === 0 ? timages.length - 1 : prevIndex - 1
    );
  };

  return (
    <>     
      <section className="three-container">
        <div className="title-nav">
          <div className="text-container">
            <div className='three'>
              <img src={ThreeWays} alt="Three Ways" />
            </div>
            <div className="ways-container">
              <div className='ways'>WAYS</div>
              <div className='ways2'>to Show Your <br/>Compassion</div>
            </div>
          </div>
          <div className="nav-buttons">
              <div onClick={prevImage}><img src={Navl} alt="<" /></div>
              <div onClick={nextImage}><img src={Navr} alt=">" /></div>
          </div>
        </div>
        <div className="image-container">
          <div className="timage">
            {timages.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Compassion ${index + 1}`}
                className={index === currentImageIndex ? 'active' : 'inactive'}
              />
            ))}
          </div>    
        </div>
      </section>
    </>
  )
}

export default Three
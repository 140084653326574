import React, { useRef } from 'react'
import "./Contact.css"
import emailjs from '@emailjs/browser'

const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_ully1hs', 'template_jr8pssx', form.current, 'JN8HQdnSC2rKncnZA')
        .then((result) => {
            console.log(result.text);
            console.log("message sent");
            e.target.reset();
        }, (error) => {
            console.log(error.text);
        });
    }

  return (
    <div id="contact" className='contact-container'>
            <div className="c-title">Get in Touch</div>
            
            <div className="c-text ">Let us know if you're dedicated to 
            providing blood resources for your<br/> community's 
            well-being and helping those in need.</div>

        <form className="contact-form" ref={form} onSubmit={sendEmail}>
            <div className="row">
                <div className="column">
                    <label htmlFor="firstName"></label>
                    <input type="text" id="firstName" name="firstName" placeholder="*First Name" required />
                </div>
                <div className="column">
                    <label htmlFor="lastName"></label>
                    <input type="text" id="lastName" name="lastName" placeholder="*Last Name" required />
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <label htmlFor="email"></label>
                    <input type="email" id="email" name="email" placeholder="*Email" required/>
                </div>
                <div className="column">
                    <label htmlFor="phone"></label>
                    <input type="tel" id="phone" name="phone" placeholder="*Phone Number" required />
                </div>
            </div>
            <div className="row">
                <div className="column">
                    {/* <label htmlFor="newDonor">New Donor:</label>
                    <input type="checkbox" id="newDonor" name="newDonor" /> */}
                    <select id="newDonor" name="newDonor">
                        <option value="existing donor">I am an existing donor</option>
                        <option value="new donor">I am a new donor</option>
                        <option value="not donor">I haven't donated in a while</option>
                    </select>
                </div>
                <div className="column">
                    {/* <label htmlFor="donate4Times">Donate 4 Times:</label>
                    <input type="checkbox" id="donate4Times" name="donate4Times" /> */}
                    <select id="goal" name="goal">
                        <option value="4 time donor a year">I will donate 4 times a year</option>
                        <option value="4 blood drives">I will host 4 blood drives</option>
                        <option value="venue">I have a venue</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <label htmlFor="companyName"></label>
                    <input type="text" id="companyName" name="companyName" placeholder="Company name / Organization (option)" />
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <label htmlFor="message"></label>
                    <textarea id="message" name="message" rows="4" placeholder="*Type your message ..."/>
                </div>
            </div>
            <div className="row">
                <button type="submit">Submit</button>
            </div>
            
        </form>
    </div>
    
  )
}

export default Contact
import React from 'react';
import "./Body.css";
import ImageSlider from "./Carousel";
import Three from "./Three";
import Contact from './Contact';
import banner1 from "../images/banner1.png"; // Import your images
import banner2 from "../images/banner2.png";
import banner3 from "../images/banner3.png";

const Body = () => {

  const images = [banner1, banner2, banner3];

  // Generate a random index to choose a random image
  const randomIndex = Math.floor(Math.random() * images.length);

  // Get the randomly selected image source
  const selectedImage = images[randomIndex];

  const mainBannerStyle = {
    backgroundImage: `url(${selectedImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '46vw',

  };

  const slides = [
    { url: "./pics/carousel1.png", title: "doctors" },
    { url: "./pics/carousel2new.png", title: "patient" },
    { url: "./pics/carousel3.png", title: "sickle cell" },
    
  ];
  const containerStyles = {
    // width: "700px",
    width: "auto",
    height: "32vw",
    margin: "0 auto", 
      
  };




  return (
  <>
    <div className='body-container'>
        <div className="main-banner" style={mainBannerStyle}>
            <div className="mb-text-container">
                <div className="mb-title universal-gap">Commit <br/>San Antonio</div>
                <div className="mb-text universal-gap">Show your compassion! Commit to donating blood 4 times a year in 2024</div>
                <a href="#contact" className="link-text">
                  <button className="commit-btn universal-gap">Commit 4</button>
                </a>
            </div>
        </div>

        <div id="program" className="compassion-container">
          <div className="cc-title universal-gap">Commit 4 Compassion Program</div>
          <div className="cc-text universal-gap">Imagine if every potential blood donor gave four 
          times a year; we'd have all we need and wouldn't have to ask for more. Unfortunately, 
          we're not there yet, and living in the nation's seventh-largest, ever-growing city 
          only increases our need for blood donations. We urgently need 
          your help to contribute to this crucial, 
          life-saving effort. Your participation truly makes a difference.</div>
          <a href="#contact" className="link-text">
            <button className="commit-btn">Commit 4</button>
          </a>
        </div>

        <div id="overview" className="carousel-container">
          <div style={containerStyles}>
            <ImageSlider slides={slides} />
          </div>        
        </div>
        <div className="slider-container">
          <Three/>
        </div>

        <Contact/>
        
    </div>
  </>
  )
}

export default Body